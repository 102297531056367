<template>
  <div class="">
    <div class="bb-container bb-container--config">
      <no-ssr>
        <portal-target name="alerts_configuration"></portal-target>
      </no-ssr>
    </div>

    <div class="g-user-sidebar">
      <div class="bb-container bb-container--config">
        <section class="g-user-sidebar__grid">
          <left-menu active="configuration"></left-menu>
          <div class="g-user-sidebar__content">
            <h2 class="g-user-sidebar__head">{{ $t("settings.myProfile")}}</h2>
            <div class="g-user-sidebar__main g-user-sidebar__main--perfil">
              <div class="config-data">
                <div class="config-data__form">
                  <div class="bb-form-fieldset">
                    <div class="bb-form-item">
                      <div class="bb-form-label">{{ $t("settings.userName") }}</div>
                      <input
                        v-model="name"
                        class="bb-form"
                        type="text"
                        maxlength="256"
                        name="name"
                        id="name"
                        required=""
                        @blur="nameBlur"
                        :placeholder="$t('settings.userNamePlaceholder')"
                      />
                      <div class="bb-form-error" v-show="nameHasError">
                        {{ $t("generic.field_is_required") }}
                      </div>
                    </div>

                    <div class="bb-form-item">
                      <div class="bb-form-label">
                        {{ $t("settings.address") }}
                      </div>
                      <input
                        v-model="adress"
                        class="bb-form"
                        type="text"
                        maxlength="256"
                        name="adress"
                        id="adress"
                        :placeholder="$t('settings.addressPlaceholder')"
                      />
                    </div>

                    <div class="bb-form-item">
                      <div class="bb-form-label">{{ $t("settings.currency") }}</div>

                      <div
                        @blur="currencyDirty = true"
                        tabindex="0"
                        class="bb-filter bb-filter--block"
                        v-click-outside="config"
                      >
                        <div
                          class="bb-filter__select bb-filter__select--block bb-filter__select--form"
                          @click="toggle"
                          :class="toggleCurrencies ? 'bb-filter__select--active' : ''"
                        >
                          {{ currencyLabel }}
                          <img
                            src="../../assets/img/ico/ico-chevron-up.svg"
                            loading="lazy"
                            alt=""
                            width="16px"
                            :class="
                              toggleCurrencies
                                ? 'bb-filter__chevron bb-filter__chevron--active'
                                : 'bb-filter__chevron '
                            "
                          />
                        </div>
                        <div class="bb-filter__items" v-show="toggleCurrencies">
                          <div
                            class="bb-filter__item bb-filter__item--option"
                            v-for="(currency, index) in currencies"
                            :key="index"
                            @click="applyCurrency(currency)"
                            :class="
                              currency.id === currencySelected
                                ? 'bb-filter__item--active'
                                : ''
                            "
                          >
                            <span v-text="currency.name" />
                          </div>
                        </div>
                      </div>

                      <div class="bb-form-error" v-show="currencyHasError">
                        {{ $t("generic.field_is_required") }}
                      </div>
                    </div>

                    <!-- <div class="bb-form-item">
                      <div class="bb-form-label">
                        Teléfono (para recibir aportaciones por Bizum)
                      </div>
                      <input
                        v-model="phone"
                        class="bb-form"
                        type="text"
                        maxlength="256"
                        name="phone"
                        id="phone"
                        placeholder="Escribe el número de teléfono"
                      />
                    </div>

                    <div class="bb-form-item">
                      <div class="bb-form-label">
                        Enlace de Paypal (para recibir aportaciones)
                      </div>
                      <input
                        v-model="paypal"
                        class="bb-form"
                        type="url"
                        maxlength="256"
                        name="paypal"
                        id="paypal"
                        placeholder="Escribe la URL de paypal"
                      />
                    </div> -->
                  </div>
                </div>

                <div class="g-user-sidebar__actions">
                  <loading-button v-if="saving"></loading-button>
                  <button
                    v-else
                    class="button button--primary button--block"
                    @click.prevent="save"
                    :disabled="disabled"
                  >
                    {{ $t("generic.save") }}
                  </button>
                </div>
              </div>

              <div class="config-photo">
                <div class="config-foto__title">
                  <div
                    class="g-user-sidebar__title-section g-user-sidebar__title-section--center g-user-sidebar__title-section--m-none"
                  >
                    {{ $t("settings.profilePicture") }}
                  </div>
                </div>
                <div class="config-photo__wrapper">
                  <no-ssr>
                    <div class="config-photo__upload-photo">
                      <img
                        v-if="user.photo != '' && photo == ''"
                        :src="getImgUrl()"
                        width="150"
                        height="150"
                        sizes="150px"
                        alt
                        class="config-photo__picture"
                      />
                      <img
                        v-if="user.photo == '' && photo == ''"
                        src="../../assets/images/embarazada.jpg"
                        width="150"
                        height="150"
                        srcset="
                          ../../assets/images/embarazada-p-500.jpeg   500w,
                          ../../assets/images/embarazada-p-800.jpeg   800w,
                          ../../assets/images/embarazada-p-1080.jpeg 1080w,
                          ../../assets/images/embarazada.jpg         1314w
                        "
                        sizes="150px"
                        alt
                        class="config-photo__picture"
                      />
                      <img
                        v-if="photo != ''"
                        :src="photo"
                        width="150"
                        height="150"
                        sizes="150px"
                        alt
                        class="config-photo__picture"
                      />
                      <input
                        type="file"
                        @change="previewPhoto"
                        name="uploadfile"
                        id="img"
                        style="display: none"
                        accept="image/*"
                      />

                      <label
                        class="button button--block button--sm button--dark-outline"
                        for="img"
                        >{{ $t("settings.changePicture") }}</label
                      >
                      <div class="error" v-if="errorMsg">
                        {{ this.errorMsg }}
                      </div>
                    </div>
                  </no-ssr>
                </div>
              </div>
            </div>
            <section class="g-user-sidebar__bba" v-if="featurePlanAvailable">
              <div class="g-user-sidebar__title-section">Plan HelloBB</div>
              <div class="g-user-sidebar__subtitle-section" v-if="planGratuito">
                Plan gratuito
              </div>
              <div class="g-user-sidebar__subtitle-section" v-else>Plan Premium</div>
              <BannerQuitarAds
                v-if="planGratuito"
                titulo="Quita la publicidad de tu lista."
              />
            </section>
            <div class="g-user-sidebar__delete">
              <span
                class="link-delete link-delete--bold link-delete--smaller cursor-pointer"
                @click.prevent="removeAccount"
              >
                <!-- <i class="uil uil-multiply"></i> -->
                {{ $t("settings.deleteAccount") }}
              </span>
            </div>
          </div>
        </section>

        <alert v-if="showAlertLocal" @retry="save()" />
      </div>
    </div>

    <modal-dialog
      :show="showDialog"
      @close="closeDialog()"
      target-class="modal__card--mini"
    >
      <div class="content-modal">
        <div class="content-modal__header content-modal__header--w90">
          <div class="content-modal__title">
            {{ $t("settings.deleteAccountTitle") }}
          </div>
          <div class="content-modal__subtitle">
            {{ $t("settings.deleteAccountSubtitle") }}
          </div>
        </div>
        <div class="content-modal__form">
          <div class="content-modal__actions">
            <bbButton
              :label="$t('settings.deleteAccount')"
              :variant="'button button--primary'"
              :loading="deletingAccount"
              @click="deleteAccount()"
            >
            </bbButton>
            <bbButton
              :label="$t('generic.cancel')"
              :variant="'button button--secondary'"
              @click="closeDialog()"
            >
            </bbButton>
          </div>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingButton from "../../components/LoadingButton";
import ConfigurationAlert from "./components/ConfigurationAlert";
import NoSSR from "vue-no-ssr";
import LeftMenu from "./components/LeftMenu";
import ModalDialog from "../../components/ui/ModalDialog";
import loginMixin from "../mixins/login";
import CanBeClosedByEscKey from "../../Mixins/CanBeClosedByEscKey";
import CloseOnClickOutside from "../../components/Mixins/CloseOnClickOutside";
import CanLogout from "../../Mixins/CanLogout";
import InputText from "../../components/forms/InputText.vue";
import bbButton from "../../components/buttons/bbButton.vue";
import vClickOutside from "v-click-outside";
import Features from "../../Mixins/Features";

import BannerQuitarAds from "../../components/ads/BannerQuitarAds.vue";

export default {
  name: "ConfigurationPage",
  components: {
    bbButton,
    InputText,
    LoadingButton,
    "no-ssr": NoSSR,
    alert: ConfigurationAlert,
    LeftMenu,
    ModalDialog,
    BannerQuitarAds,
  },
  mixins: [
    loginMixin,
    CanLogout,
    Features,
  ],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  metaInfo() {
    return {
      title: this.$t("generic.settings") + " | HelloBB"
    };
  },
  data() {
    return {
      email: "",
      name: "",
      nameDirty: false,
      adress: "",
      // phone: "",
      // paypal: "",
      photo: "",
      loading: false,
      errorMsg: null,
      preview: false,
      saving: false,
      showDialog: false,
      password: "",
      deletingAccount: false,
      error: false,
      currencySelected: null,
      currencyDirty: false,
      toggleCurrencies: false,
      config: {
        handler: this.closeCurrencies,
        events: ["dblclick", "click"],
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      showAlertKey: "showAlertKey",
      showAlert: "showAlert",
      user: "user",
      currencies: "currencies",
      loggedInUserIsPremium: "loggedInUserIsPremium",
    }),
    showAlertLocal() {
      return this.showAlertKey === this.key && this.showAlert;
    },
    key() {
      return "configuration_page";
    },
    disabled() {
      //if (this.name !== "" && this.$utils.isValidUrl(this.paypal)) {
      //  return false;
      //}
      //return true;

      return false;
    },
    nameHasError() {
      return this.name === "" && this.nameDirty;
    },
    photoSrc() {
      return this.preview && this.errorMsg === null
        ? this.photo
        : this.$getUserImgUrl(this.list.photo);
    },
    currencyLabel() {
      return this.currencies.find((currency) => currency.id === this.currencySelected)
        ?.name;
    },
    currencyHasError() {
      return this.currencySelected === null && this.currencyDirty;
    },
    planGratuito() {
      return !this.loggedInUserIsPremium;
    },
  },
  methods: {
    removeAccount() {
      this.showDialog = true;
    },
    async deleteAccount() {
      this.deletingAccount = true;
      await this.$store.dispatch("deleteUserAccount", this.user);
      this.deletingAccount = false;
      await this.logout();
      await this.$router.push({ name: "seeyousoon" });
    },
    closeDialog() {
      this.showDialog = false;
    },
    getImgUrl() {
      return process.env.URL_IMG_USER + this.user.photo;
    },
    async save() {
      if (this.currencySelected === null) {
        this.currencyDirty = true;
        return;
      }

      this.saving = true;
      try {
        const newUser = {
          name: this.name,
          //phone: this.phone,
          //paypal: this.paypal,
          adress: this.adress,
        };
        await this.$store.dispatch("updateUser", newUser);
        if (this.photo !== "") await this.uploadPhoto();

        const currencyId = this.currencySelected;
        await this.$store.dispatch("updateUserCurrency", {
          currencyId,
        });
      } catch (error) {
        console.log(error);
        this.saving = false;
        this.$alert.error(
          this.$t("generic.errorNoSave"),
          this.key
        );

        return;
      }
      await this.$store.dispatch("getOwnerList");
      this.$alert.success(this.$t("generic.saveMessage"), this.key);
      this.saving = false;
    },
    nameBlur() {
      this.nameDirty = true;
    },
    previewPhoto(event) {
      this.preview = true;
      var input = event.target;
      this.errorMsg = null;
      if (input.files && input.files[0]) {
        const maxAllowedSize = 4 * 1024 * 1024;
        if (input.files[0].size > maxAllowedSize) {
          this.errorMsg = "Tamaño máximo 4MB";
          input.value = "";
          return;
        }
        var reader = new FileReader();
        reader.onload = (e) => {
          this.photo = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async uploadPhoto() {
      let photo = this.photo
        .replace(/^data:image\/[a-zA-Z]+;base64,/, "")
      await this.$store.dispatch("setUserImage", { content: photo });
    },
    toggle() {
      this.toggleCurrencies = !this.toggleCurrencies;
    },
    applyCurrency(currency) {
      this.currencySelected = currency.id;
      this.toggleCurrencies = false;
    },
    closeCurrencies() {
      this.toggleCurrencies = false;
    },
  },
  async serverPrefetch() {
    await this.$store.dispatch("getCurrencies", true);
  },
  async mounted() {
    this.email = this.user.email;
    this.name = this.user.name;
    //this.phone = this.user.phone;
    //this.paypal = this.user.paypal;
    this.adress = this.user.adress;

    await this.$store.dispatch("getCurrencies");
    this.currencySelected = this.currencies.find((currency) => currency.isoCode === this.user.currencyISOCode)?.id;
  },
};
</script>
